import FAQItem from '@components/FAQ/Item';
import FAQList from '@components/FAQ/List';
import Link from '@components/Link';
import background from '@images/007/background.svg';
import backgroundTrain from '@images/007/train.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';
import SponsorList from '@components/Sponsors/List';
import Img from 'gatsby-image';

import './fonts.css';
import styles from './custom.module.css';
import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Footer from '@components/Layout/Footer';
import HackCU007Header from '@components/Layout/Header007';

const HackathonCard: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	children,
	...rest
}) => (
	<div {...rest} className={clsx('bg-hackcu7-gray-500 rounded-md p-2', className)}>
		{children}
	</div>
);

/**

Capsize for the hackcu text:
capsize({
	fontMetrics: {
		capHeight: 660,
		ascent: 1023,
		descent: -277,
		lineGap: 0,
		unitsPerEm: 1000,
	},
	capHeight: 100,
})

Capsize for the 007 text:
capsize({
	// fontMetrics: {
	// 	capHeight: 900,
	// 	ascent: 1400,
	// 	descent: -600,
	// 	lineGap: 174,
	// 	unitsPerEm: 2048,
	// },
	fontMetrics: {
		capHeight: 900,
		ascent: 1200,
		descent: -500,
		lineGap: 174,
		unitsPerEm: 2048,
	},
	capHeight: 100,
	lineGap: 0,
})
 */

const IndexPage = () => {
	//const { theme } = useThemeUI();
	const {
		data,
		schedule1,
		schedule2,
		FAQdata,
		sponsorsData: { nodes: sponsors },
	} = useStaticQuery(graphql`
		{
			data: allHackcu8TracksYaml {
				nodes {
					title
					description
				}
			}

			schedule1: allHackcu8Schedule1Yaml {
				nodes {
					time
					event
				}
			}

			schedule2: allHackcu8Schedule2Yaml {
				nodes {
					time: time2
					event: event2
				}
			}

			FAQdata: allHackcu007FaqYaml {
				nodes {
					question
					answer
				}
			}

			sponsorsData: allSponsorsYaml {
				nodes {
					name
					tier
					url
					image {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`);

	// useEffect(() => {
	// 	document.querySelector('html').
	// }, [])

	return (
		<>
			<GatsbySeo
				title="HackCU 007"
				description="Our 7th hackathon, which will be virtual this year."
			/>

			<HackCU007Header />

			<div className="relative">
				<img
					className="w-full relative bottom-0 select-none"
					src={background as any}
					alt="London"
					style={{
						zIndex: -2,
					}}
					id="home"
				/>
				<div className={clsx(styles.train, 'absolute bottom-0 select-none')}>
					<img
						src={backgroundTrain as string}
						alt="Train"
						className={clsx('w-0 sm:w-16 md:w-96 relative bottom-0', styles.trainImg)}
						style={{ zIndex: -2 }}
					/>
				</div>

				<div className="absolute inset-0 sm:top-0 sm:left-0 sm:right-auto sm:bottom-auto">
					<div className="mt-3 sm:mt-8 ml-0 sm:ml-8">
						<h1 className={clsx('font-bold text-center sm:text-left', styles.title)}>HACKCU</h1>

						<h1
							className={clsx('text-center sm:text-left', styles.subtext)}
							style={{
								fontFamily: '"Golden Eye", sans-serif',
							}}
						>
							007*
						</h1>
						<h2 className="font-bold text-5xl text-center sm:text-left">March 6 - 7</h2>
					</div>
				</div>
			</div>

			<div className="w-full bg-hackcu7-background-500">
				<div className="mx-auto max-w-7xl px-3 xl:px-0">
					<div className="space-y-8 pt-5">
						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="about">
								About
							</h1>
							<p>
								On March 6-7, we will be hosting our flagship annual hackathon, HackCU 007, in a
								virtual format, making it our biggest virtual hackathon yet! You will have the
								chance to create projects in any of the provided tracks. Submit your project to win
								a number of special prizes, or attend a few of our many workshops and seminars.
								Although we are disappointed that we are not able to host our in-person event, we
								are still very excited to provide students the opportunity to bring their projects
								to life!
							</p>
						</HackathonCard>

						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="tracks">
								Tracks
							</h1>

							<div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
								{data.nodes.map(({ title, description }) => (
									<div className="py-3 px-2 text-center" key={title}>
										<h2 className="font-bold text-3xl mb-1">{title}</h2>
										{description.startsWith('function') ? (
											<MDXRenderer>{description}</MDXRenderer>
										) : (
											<p>{description}</p>
										)}
									</div>
								))}
							</div>
						</HackathonCard>

						<HackathonCard className="px-5">
							<div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
								<div>
									<h1 className="text-center font-bold text-5xl mb-3">Our Story</h1>
									<p>
										HackCU was started in 2014 by a group of passionate students who wanted to
										provide other students the time and space to work on projects they don&apos;t
										normally work on through school. Since then we&apos;ve been committed to
										fulfilling our mission of bringing you high-quality, inclusive events open to
										people of all levels of experience.
									</p>
								</div>
								<div>
									<h1 className="text-center font-bold text-5xl mb-3">Why You?</h1>

									<p>
										Like to write programs? Dig into interesting datasets? Design creative works? Or
										maybe you&apos;ve never written a line of code? We will provide the space and
										the resources for you to express your creativity, ingenuity, and determination
										to create anything you want to. Whether you&apos;re building your first website,
										dabbling in Photoshop, or hacking with an Oculus Rift, HackCU is for you. HackCU
										is for people of all backgrounds and experiences. So, come and join us!
									</p>
								</div>
							</div>
						</HackathonCard>

						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="faq">
								FAQs
							</h1>
							<FAQList>
								{FAQdata.nodes.map(({ question, answer }) => (
									<FAQItem key={question} question={question} answer={answer} />
								))}
							</FAQList>
						</HackathonCard>

						{/* <HackathonCard className="text-center space-y-4">
							<h1 className="text-center font-bold text-5xl" id="events">
								Schedule
							</h1>

							<h2 className="font-bold text-2xl">Check back later!</h2>
						</HackathonCard> */}

						<HackathonCard>
							<h1 className="text-center font-bold text-5xl" id="events">
								Schedule
							</h1>

							<div className="flex flex-column flex-row justify-center">
								<div className="mx-3  flexGrow-1">
									<h1 className="font-bold text-3xl text-center mb-3">Day 1</h1>
									<table className="border-collapse w-full">
										<thead className="text-md">
											<th>Time</th>
											<th>Event</th>
										</thead>
										<tbody>
											{schedule1.nodes.map(({ time, event }, index) => (
												<tr
													key={event}
													style={{ borderTop: index === 0 ? '2px solid gray' : '1px solid gray' }}
												>
													<td className="p-1  text-center">
														<p>{time}</p>
													</td>

													<td className="p-1 text-center">
														{event.startsWith('function') ? (
															<MDXRenderer>{event}</MDXRenderer>
														) : (
															<p>{event}</p>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="mx-3  flexGrow-1">
									<h1 className="font-bold text-3xl text-center mb-3">Day 2</h1>
									<table className="border-collapse w-full">
										<thead className="text-md">
											<th>Time</th>
											<th>Event</th>
										</thead>
										<tbody>
											{schedule2.nodes.map(({ time, event }, index) => (
												<tr
													key={event}
													style={{ borderTop: index === 0 ? '2px solid gray' : '1px solid gray' }}
												>
													<td className="p-1  text-center">
														<p>{time}</p>
													</td>

													<td className="p-1 text-center">
														{event.startsWith('function') ? (
															<MDXRenderer>{event}</MDXRenderer>
														) : (
															<p>{event}</p>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</HackathonCard>

						<HackathonCard className="space-y-4 text-center">
							<h1 className="text-center font-bold text-5xl" id="mentor">
								Mentor
							</h1>
							<h2 className="text-center font-bold text-2xl">Want to build HackCU with us?</h2>
							<h2 className="text-center font-bold text-2xl">
								Help us make this happen and welcome over 400 awesome hackers.
							</h2>

							<Link
								outside
								to="https://docs.google.com/forms/d/e/1FAIpQLSdyLVr7vfCA9KxGQP_C4EeVh0pNVDsGTHctaqQZYFPypEDDNw/viewform"
								className="block"
							>
								<button className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-hackcu7-primary-500 rounded-md">
									Mentor
								</button>
							</Link>
						</HackathonCard>

						<div className="bg-hackcu7-gray-500 rounded-md p-2 text-center">
							<h1 className="text-center font-bold text-5xl" id="sponsors">
								Sponsors
							</h1>

							<SponsorList>
								{sponsors.map(({ name, url, image }) => (
									<div key={name} className="inline-block my-auto" data-testid="sponsor-item">
										<Link outside to={url} className="p-3 block w-full" data-testid="sponsor-item">
											<Img
												imgStyle={{
													objectFit: 'contain',
												}}
												alt={`${name} logo`}
												fluid={image.childImageSharp.fluid}
											/>
										</Link>
									</div>
								))}
							</SponsorList>
						</div>
					</div>

					<div className="flex text-center mt-4">
						<p className="m-auto w-full">
							<Link outside to="https://iconscout.com/icons/007">
								007 Icon
							</Link>{' '}
							by{' '}
							<Link outside to="https://iconscout.com/contributors/icons8">
								Icons8
							</Link>{' '}
							on{' '}
							<Link outside to="https://iconscout.com">
								Iconscout
							</Link>
						</p>
					</div>

					<Footer />
				</div>
			</div>
		</>
	);
};

export default IndexPage;
