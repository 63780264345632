import Link from '@components/Link';
import logo from '@images/hackcu-logo.svg';
import pinnacleLogo from '@images/pinnacle/PinnacleBadge_BoG_PinBadgeResize.svg';
import React, { HTMLAttributes, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { AnchorLink as CustomLink, AnchorLinkProps } from 'gatsby-plugin-anchor-links';
import clsx from 'clsx';

const AnchorLink: React.FC<AnchorLinkProps & { onClick?: () => void }> = ({
	onClick,
	...props
}) => (
	<div onClick={onClick} className="p-2 sm:py-0">
		<CustomLink className="font-bold text-center font-xl" {...props} />
	</div>
);

const HackCU007Header: React.FC<HTMLAttributes<HTMLHeadingElement>> = (props) => {
	const [open, setOpen] = useState(false);

	const goto = () => {
		console.log('clicked');
		setOpen(false);
	};

	return (
		<header
			className="flex items-center flex-col md:flex-row bg-hackcu7-background-500 sticky top-0 py-2"
			style={{ zIndex: 10 }}
			{...props}
		>
			<div className="flex px-3 md:px-0 w-full md:w-auto">
				<Link to="/">
					<div className="ml-0 md:ml-3">
						<img
							className="mr-0 md:mr-4"
							src={logo as string}
							alt="HackCU Logo"
							style={{ height: 60 }}
						/>
					</div>
				</Link>
				{/* TODO: fix weird slide bug when changing from mobile to desktop screen size */}
				<div
					className="flex ml-auto cursor-pointer visible md:invisible"
					onClick={() => setOpen(!open)}
					aria-expanded={open}
					aria-controls="nav-links"
					tabIndex={0}
					role="button"
					aria-label="Navigation trigger"
				>
					<div className="m-auto">
						<IconContext.Provider value={{ size: '2rem' }}>
							<FaBars />
						</IconContext.Provider>
					</div>
				</div>
			</div>

			<div
				className={clsx(
					'flex mt-2 md:mt-0 ml-0 md:ml-auto pr-0 md:pr-3 flex-col md:flex-row overflow-hidden items-center',
					open ? 'max-h-screen' : 'max-h-0',
					'md:max-h-screen',
				)}
				id="nav-links"
				role="navigation"
			>
				<AnchorLink to="/007#home" onClick={goto}>
					Home
				</AnchorLink>
				<AnchorLink to="/007#about" onClick={goto}>
					About
				</AnchorLink>
				<AnchorLink to="/007#tracks" onClick={goto}>
					Tracks
				</AnchorLink>
				<AnchorLink to="/007#faq" onClick={goto}>
					FAQ
				</AnchorLink>
				{/* <AnchorLink   to="/007#events" onClick={goto}>
					Event Schedule
				</AnchorLink> */}
				<AnchorLink to="/007#mentor" onClick={goto}>
					Mentor
				</AnchorLink>
				<AnchorLink to="/007#sponsors" onClick={goto}>
					Sponsors
				</AnchorLink>
				<Link outside to="https://forms.gle/87mVPr4BBJjGY1Di9">
					<button className="font-bold cursor-pointer text-white bg-hackcu7-primary-500 p-3 rounded-md">
						Register
					</button>
				</Link>
				<Link outside to="https://pinnacle.us.org/">
					<div className="ml-0 md:ml-3">
						<img
							className="mr-0 md:mr-4"
							src={pinnacleLogo as string}
							alt="HackCU Logo"
							style={{ height: 100 }}
						/>
					</div>
				</Link>
			</div>
		</header>
	);
};

export default HackCU007Header;
